






































































































































import { toRef, computed, defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import userAddressesGetters from '~/modules/customer/getters/userAddressesGetters';
import type { TransformedCustomerAddress } from '~/modules/customer/composables/types';

export default defineComponent({
  name: 'UserAddressDetails',
  props: {
    manageableAddress: {
      type: Boolean,
      default: false,
    },
    showDefaultButtons: {
      type: Boolean,
      default: false,
    },
    editableAddress: {
      type: Boolean,
      default: false,
    },
    useableAddress: {
      type: Boolean,
      default: true,
    },
    address: {
      type: Object as PropType<TransformedCustomerAddress>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const address = toRef(props, 'address');

    const userAddress = computed(() => ({
      firstName: userAddressesGetters.getFirstName(address.value),
      lastName: userAddressesGetters.getLastName(address.value),
      street: userAddressesGetters.getStreetName(address.value),
      streetNumber: userAddressesGetters.getApartmentNumber(address.value),
      postalCode: userAddressesGetters.getPostCode(address.value),
      city: userAddressesGetters.getCity(address.value),
      province: userAddressesGetters.getProvince(address.value) || '',
      country: userAddressesGetters.getCountry(address.value),
      phone: userAddressesGetters.getPhone(address.value),
      isDefault: userAddressesGetters.isDefault(address.value),
      isDefaultShipping: userAddressesGetters.isDefaultShipping(address.value),
      isDefaultBilling: userAddressesGetters.isDefaultBilling(address.value),
    }));

    const isDefaultShippingText = computed(() => (userAddress.value.isDefaultShipping ? 'default shipping address' : ''));

    const isDefaultBillingText = computed(() => (userAddress.value.isDefaultBilling ? 'default billing address' : ''));

    const useAddress = () => {
      emit('use-address');
    };

    const editAddress = () => {
      emit('edit-address');
    };

    const deleteAddress = () => {
      emit('delete-address');
    };

    const makeAddressDefault = (value: {
      addressType: string;
      value: boolean;
    }) => {
      emit('make-address-default', value);
    };

    return {
      userAddress,
      isDefaultShippingText,
      isDefaultBillingText,
      makeAddressDefault,
      deleteAddress,
      editAddress,
      useAddress,
    };
  },
});
